import React from "react";
import styled from "styled-components";
import CustomDoubleTitle from "../General/CustomDoubleTitle";
import { Timeline } from "antd";
import { Element } from "react-scroll";

const SubscriptionStepSectionContainer = styled.div`
  width: 100%;
  background-color: #000e14;
  padding: 40px 0;
`;

const SubscriptionStepSectionInnerContainer = styled.div`
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;

  @media only screen and (max-width: 1430px) {
    max-width: 1250px;
  }
`;

const SubscriptionStepSectionContentWrapper = styled.div`
  padding: 0;

  @media only screen and (max-width: 1280px) {
    padding: 0 10px;
  }

  @media only screen and (max-width: 1200px) {
    padding: 0 30px;
  }

  @media only screen and (max-width: 942px) {
    padding: 0 15px;
  }
`;

const TimelineWrapper = styled.div`
  max-width: 1206px;
  width: 100%;
  margin: 0 auto;
`;

const TimelineInnerWrapper = styled.div`
  padding: 70px 0 0;

  & .ant-timeline-item-head {
    background-color: #0fd4fb;
    width: 17px;
    height: 17px;
  }

  & .ant-timeline-item {
    padding-bottom: 50px;
  }

  & .ant-timeline-item-tail {
    border-inline-start: 1px solid rgba(255, 255, 255, 1);
    inset-inline-start: 8px;
  }
`;

const TimelineChildrenWrapper = styled.div`
  border: 0.5px solid rgba(3, 121, 255, 0.5);
  border-radius: 16px;
  padding: 16px;
`;

const EachTimelineTitle = styled.h2`
  font-size: 24px;
  font-weight: 500;
  line-height: 31.92px;
  text-align: left;
  color: #fff;

  @media only screen and (max-width: 600px) {
    font-size: 18px;
    line-height: 27px;
  }
`;

const EachTimelineDesc = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #d4d4d4;
  padding-top: 15px;
`;

const SubscriptionStep = () => {
  const EachTimelineCmp = ({ data }) => {
    return (
      <TimelineChildrenWrapper>
        <EachTimelineTitle>{data.title}</EachTimelineTitle>
        <EachTimelineDesc>{data.desc}</EachTimelineDesc>
      </TimelineChildrenWrapper>
    );
  };
  return (
    <Element name="how-it-works">
      <SubscriptionStepSectionContainer>
        <SubscriptionStepSectionInnerContainer>
          <SubscriptionStepSectionContentWrapper>
            <CustomDoubleTitle
              firstTitle={"SUBSCRIPTION STEPS"}
              secondTitle={"Here's how it works"}
            />

            <TimelineWrapper>
              <TimelineInnerWrapper>
                <Timeline
                  items={[
                    {
                      color: "#0FD4FB",
                      children: (
                        <EachTimelineCmp
                          data={{
                            title: "Onboarding",
                            desc: "Onboarding takes just 30 minutes and it's the only mandatory call in our service. We can set one up as soon as tomorrow.",
                          }}
                        />
                      ),
                    },
                    {
                      color: "#0FD4FB",
                      children: (
                        <EachTimelineCmp
                          data={{
                            title: "Add Projects",
                            desc: "We use a simple, self-directed ClickUp board to manage work. You can add projects, leave comments, and view progress—or let us add tasks for you.",
                          }}
                        />
                      ),
                    },
                    {
                      color: "#0FD4FB",
                      children: (
                        <EachTimelineCmp
                          data={{
                            title: "Weekly Consultations",
                            desc: "Ops is an ongoing process. We provide (optional) weekly 45-minute consultations for brainstorming & discussion.",
                          }}
                        />
                      ),
                    },
                  ]}
                />
              </TimelineInnerWrapper>
            </TimelineWrapper>
          </SubscriptionStepSectionContentWrapper>
        </SubscriptionStepSectionInnerContainer>
      </SubscriptionStepSectionContainer>
    </Element>
  );
};

export default SubscriptionStep;
