import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Blog from "./pages/Blog";
import Contact from "./pages/Contact";
import CaseStudy from "./pages/CaseStudy";
import axios from "axios";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";

function App() {
  useEffect(() => {
    const getCookieValue = (name) => {
      const cookieName = `${name}=`;
      const decodedCookie = decodeURIComponent(document.cookie);
      const cookieArray = decodedCookie.split(";");
      for (let i = 0; i < cookieArray.length; i++) {
        let cookie = cookieArray[i];
        while (cookie.charAt(0) === " ") {
          cookie = cookie.substring(1);
        }
        if (cookie.indexOf(cookieName) === 0) {
          return cookie.substring(cookieName.length, cookie.length);
        }
      }
      return "";
    };

    const fbcValue = getCookieValue("_fbc");
    console.log(fbcValue);

    const fetchIpAddress = async () => {
      try {
        const response = await fetch("https://api.ipify.org/?format=json");
        const data = await response.json();
        return data.ip;
      } catch (error) {
        console.error("Error fetching IP address:", error);
        return ""; // Return empty string in case of error
      }
    };

    const fetchData = async () => {
      const ipAddress = await fetchIpAddress();
      const userAgent = navigator.userAgent;

      axios.get(
        `https://capi.bles-software.com?event=page_view&pid=936189111057967&ip=${ipAddress}&ua=${userAgent}&accessTokens=EAATkZAMb9LmoBOZC8dxSqZAp1T4Blv2gmfVJp0IZB4vm2Qgp0Jnh6zudv5VBaMzWZAOMMwrp3Y07YjkFLlRVQUPI0ZCcZBrSUbVoPmBDWV9KoiwAyT4hEbK26pWGpyJBzMKFRvUYmhsRopfcyEgFJOZAtQaOBxX6bzq5KSwRybOjIva0ShccIvAhZB2o7oRglrqCxfAZDZD&fbc=${fbcValue}`
      );
    };

    fetchData();
  }, []);
  return (
    <Router>
      <Helmet>
        {/* Hotjar Tracking Code for https://ai-gig.bles-software.com  */}
        <script>
          {`
 (function(h,o,t,j,a,r){
  h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
  h._hjSettings={hjid:3886396,hjsv:6};
  a=o.getElementsByTagName('head')[0];
  r=o.createElement('script');r.async=1;
  r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
  a.appendChild(r);
})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}
        </script>
      </Helmet>
      <Routes>
        {/* <Route path="*" element={<ErrorPage />} /> */}
        <Route path="/" element={<Home />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/case-study" element={<CaseStudy />} />
      </Routes>
    </Router>
  );
}

export default App;
