import React from "react";
import styled from "styled-components";

const FirstTitleWrapper = styled.div`
  flex-grow: 1; /* Allows the wrapper to grow and fill available space */
  display: flex; /* Makes the title wrapper a flex container */
  justify-content: flex-start; /* Centers the title */
  position: relative; /* For z-index stacking */
  z-index: 1; /* Places the title above the line */

  /* for the line */
  &::before {
    content: ""; /* Necessary for the pseudo-element */
    position: absolute; /* Position relative to the title wrapper */
    top: 50%; /* Aligns the line to the middle vertically */
    right: 99.9%; /* Start of the line at the left edge of the title wrapper */
    left: -11px; /* Stops the line halfway across the container */
    border-top: 1px solid #0fd4fb; /* The actual line */
    transform: translateY(-50%); /* Centers the line vertically */
    z-index: -1; /* Places the line behind the text */

    /* @media only screen and (max-width: 1100px) {
      left: 75%;
    }

    @media only screen and (max-width: 765px) {
      left: 80%;
    }

    @media only screen and (max-width: 425px) {
      left: 90%;
    } */
  }
`;

const FirstTitle = styled.h2`
  /* needed for the line */
  padding: 0;
  display: inline-block;
  z-index: 2;
  /* needed for the line */

  color: #0fd4fb;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.94px;
  letter-spacing: 0.9599999785423279px;
  text-align: left;

  @media only screen and (max-width: 600px) {
    font-size: 16px;
    line-height: 19.5px;
  }
`;

const SecondTitleWrapper = styled.div`
  flex-grow: 1; /* Allows the wrapper to grow and fill available space */
  display: flex; /* Makes the title wrapper a flex container */
  justify-content: flex-start; /* Centers the title */
  position: relative; /* For z-index stacking */
  z-index: 1; /* Places the title above the line */
  padding-top: 5px;

  /* for the line */
  &::before {
    content: ""; /* Necessary for the pseudo-element */
    position: absolute; /* Position relative to the title wrapper */
    top: 50%; /* Aligns the line to the middle vertically */
    left: 65%; /* Start of the line at the left edge of the title wrapper */
    right: -5px; /* Stops the line halfway across the container */
    border-top: 1px solid #fff; /* The actual line */
    transform: translateY(-50%); /* Centers the line vertically */
    z-index: -1; /* Places the line behind the text */

    @media only screen and (max-width: 1234px) {
      left: 75%;
    }

    @media only screen and (max-width: 1122px) {
      left: 85%;
    }

    @media only screen and (max-width: 572px) {
      left: 90%;
    }

    @media only screen and (max-width: 520px) {
      left: 99%;
    }
  }
`;

const SecondTitle = styled.h2`
  /* needed for the line */
  padding: 0;
  display: inline-block;
  z-index: 2;
  /* needed for the line */

  font-size: 36px;
  font-weight: 500;
  line-height: 43.88px;
  letter-spacing: 0.9599999785423279px;
  text-align: left;
  color: #fff;

  @media only screen and (max-width: 1022px) {
    font-size: 30px;
  }

  @media only screen and (max-width: 816px) {
    font-size: 26px;
  }

  @media only screen and (max-width: 728px) {
    font-size: 24px;
  }

  @media only screen and (max-width: 674px) {
    font-size: 22px;
  }

  @media only screen and (max-width: 600px) {
    font-size: 20px;
    line-height: 24.38px;
  }
`;

const CustomDoubleTitle = ({ firstTitle, secondTitle }) => {
  return (
    <>
      <FirstTitleWrapper>
        <FirstTitle>{firstTitle}</FirstTitle>
      </FirstTitleWrapper>
      <SecondTitleWrapper>
        <SecondTitle>{secondTitle}</SecondTitle>
      </SecondTitleWrapper>
    </>
  );
};

export default CustomDoubleTitle;
