import React from "react";
import styled from "styled-components";

const Span = styled.span`
  position: relative;

  &::after {
    background-image: url("/assets/custom-underline.svg");
    background-repeat: no-repeat;
    background-size: cover;
    bottom: -15px;
    content: "";
    height: 1.75rem;
    left: -0.5rem;
    position: absolute;
    z-index: 0;
    width: 100%;
  }
`;

const CustomUnderline = ({ children }) => {
  return <Span>{children}</Span>;
};

export default CustomUnderline;
