import { Tag } from "antd";
import React from "react";
import styled from "styled-components";

const CaseStudyHeaderContainer = styled.div`
  width: 100%;
  background-size: cover !important;
  background: url("/assets/case-study-detail.jpg"),
    linear-gradient(
      90deg,
      rgba(15, 15, 15, 0.8) 28.51%,
      rgba(15, 15, 15, 0) 100%
    );
  background-repeat: no-repeat;
  background-blend-mode: multiply;
`;

const CaseStudyInnerHeaderContainer = styled.div`
  padding: 60px 15px;
`;

const CaseStudyInnerHeaderContent = styled.div`
  max-width: 754px;
  width: 100%;
  text-align: left;
`;

const HeaderText = styled.h2`
  color: #fff;
  font-size: 36px;
  font-weight: 600;
  line-height: 44.14px;
  letter-spacing: 0.9599999785423279px;
  text-align: left;

  @media only screen and (max-width: 600px) {
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 1.2000000476837158px;
  }
`;

const SmallText = styled.p`
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
  padding: 20px 0 15px;

  @media only screen and (max-width: 600px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

const FeaturesWrapper = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  max-width: 754px;
  width: 100%;
  padding-top: 20px;
`;

const TagWrapper = styled.div`
  display: flex;
  justify-content: center;
  max-width: 150px;
  width: 100%;

  @media only screen and (max-width: 349px) {
    max-width: 134px;
  }

  & .ant-tag {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px;
    border-radius: 24px;
    background: #0e678f66;
    color: #0fd4fb;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    border: none;
  }
`;

const CaseStudyDetail = () => {
  return (
    <CaseStudyHeaderContainer>
      <CaseStudyInnerHeaderContainer>
        <CaseStudyInnerHeaderContent>
          <HeaderText>
            Using AI in real-world applications for profit
          </HeaderText>
          <SmallText>
            We'll analyze your current processes and provide you with a free
            analysis on how to optimize your agency - no strings attached. Get
            tens of thousands of dollars of value, absolutely free.
          </SmallText>

          <FeaturesWrapper>
            {["SAAS", "AI", "Business", "Optimization"].map((v, i) => {
              return (
                <TagWrapper>
                  <Tag>
                    <img
                      src="/assets/checkbox.svg"
                      alt="check"
                      style={{ paddingRight: "2px" }}
                    />{" "}
                    {v}
                  </Tag>
                </TagWrapper>
              );
            })}
          </FeaturesWrapper>
        </CaseStudyInnerHeaderContent>
      </CaseStudyInnerHeaderContainer>
    </CaseStudyHeaderContainer>
  );
};

export default CaseStudyDetail;
