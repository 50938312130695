import { Button } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CustomUnderline from "../General/CustomUnderline";
import { Link } from "react-scroll";

const HeroContainer = styled.div`
  width: 100%;
  background-color: #000e14;
  padding: 40px 0;
`;

const HeroInnerContainer = styled.div`
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;

  @media only screen and (max-width: 1430px) {
    max-width: 1250px;
  }
`;

const HeroContentWrapper = styled.div`
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  @media only screen and (max-width: 1280px) {
    padding: 0 10px;
  }

  @media only screen and (max-width: 1200px) {
    padding: 0 30px;
  }

  @media only screen and (max-width: 942px) {
    padding: 0 15px;
    flex-direction: column;
    justify-content: center;
    gap: 40px;
  }
`;

const LeftContentWrapper = styled.div`
  max-width: 851px;
  width: 100%;
`;

const TextTitle = styled.h2`
  color: #fff;
  font-size: 48px;
  font-weight: 600;
  line-height: 72px;
  letter-spacing: 1.2000000476837158px;
  text-align: left;

  @media only screen and (max-width: 1200px) {
    font-size: 38px;
    line-height: 60px;
  }

  @media only screen and (max-width: 980px) {
    font-size: 30px;
    line-height: 50px;
  }

  @media only screen and (max-width: 942px) {
    text-align: center;
  }

  @media only screen and (max-width: 600px) {
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    text-align: center;
  }
`;

const DescText = styled.p`
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
  padding: 20px 0 60px;
  max-width: 748px;
  width: 100%;

  @media only screen and (max-width: 942px) {
    text-align: center;
    margin: 0 auto;
    max-width: 100%;
  }

  @media only screen and (max-width: 600px) {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
  }
`;

const ButtonGroupContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  max-width: 400px;

  @media only screen and (max-width: 942px) {
    justify-content: center;
    margin: 0 auto;
    gap: 20px;
  }
`;

const IntroButtonContainer = styled.div`
  display: flex;
  justify-content: center;

  & .ant-btn-primary,
  & .ant-btn-primary:hover,
  & .ant-btn-primary:focus {
    padding: 13px 20px;
    background: linear-gradient(
      94.04deg,
      #0379ff 0%,
      #1e94ab 40.32%,
      #0379ff 87.36%
    ) !important;
    border: none !important;
    font-size: 16px;
    font-weight: 700;
    line-height: 28px;
    text-align: center;
    border-radius: 12px;
    height: 48px;
  }

  @media only screen and (max-width: 600px) {
    & .ant-btn-primary,
    & .ant-btn-primary:hover,
    & .ant-btn-primary:focus {
      font-size: 14px;
      font-weight: 700;
      line-height: 28px;
      height: 40px;
    }
  }
`;

const IntroButton = styled(Button)`
  width: 190px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: none; // Removes the default border

  @media only screen and (max-width: 600px) {
    width: 160px;
  }

  @media only screen and (max-width: 355px) {
    width: 145px;
  }
`;

const ViewPricingContainer = styled.div`
  display: flex;
  justify-content: center;

  & .ant-btn-primary,
  & .ant-btn-primary:hover,
  & .ant-btn-primary:focus {
    padding: 13px;
    background: transparent !important;
    border: 2px solid #0fd4fb;
    font-size: 16px;
    font-weight: 700;
    line-height: 28px;
    text-align: center;
    border-radius: 12px;
    height: 48px;
  }

  @media only screen and (max-width: 600px) {
    & .ant-btn-primary,
    & .ant-btn-primary:hover,
    & .ant-btn-primary:focus {
      font-size: 14px;
      font-weight: 700;
      line-height: 28px;
      height: 40px;
    }
  }
`;

const ViewPricingText = styled.span`
  background-color: #fff;
  background-image: linear-gradient(
    86.56deg,
    #0fd4fb -2.06%,
    #2ba3ba 51.2%,
    #0fd4fb 97.16%
  );
  background-image: conic-gradient(
    86.56deg,
    #0fd4fb -2.06%,
    #2ba3ba 51.2%,
    #0fd4fb 97.16%
  );
  background-size: 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
`;

const RightContentWrapper = styled.div`
  position: relative;
  text-align: center;
`;

const RightImage = styled.img`
  z-index: 100;
  position: relative;

  @media only screen and (max-width: 942px) {
    width: 100%;
  }

  @media only screen and (max-width: 480px) {
    width: 90%;
  }
`;

const Ellipse1 = styled.img`
  position: absolute;
  right: 30px;
  top: 100px;
  z-index: 99;

  @media only screen and (max-width: 480px) {
    transform: scale(0.7);
    right: 16px;
    top: 39px;
  }
`;

const Ellipse2 = styled.img`
  position: absolute;
  left: -30px;
  top: 0px;
  z-index: 99;

  @media only screen and (max-width: 480px) {
    transform: scale(0.7);
  }
`;

const Ellipse3 = styled.img`
  position: absolute;
  right: 10px;
  bottom: 100px;
  z-index: 99;

  @media only screen and (max-width: 480px) {
    transform: scale(0.7);
    bottom: 40px;
  }
`;

const Ellipse4 = styled.img`
  position: absolute;
  right: 105px;
  top: 42px;
  z-index: 99;

  @media only screen and (max-width: 480px) {
    transform: scale(0.7);
  }
`;

const Ellipse5 = styled.img`
  position: absolute;
  left: -20px;
  bottom: 100px;
  z-index: 99;

  @media only screen and (max-width: 480px) {
    transform: scale(0.7);
    left: -2px;
    bottom: 57px;
  }
`;

const ClientContentWrapper = styled.div`
  display: none;
  padding: 60px 0 20px;

  @media only screen and (max-width: 1280px) {
    padding: 60px 10px 20px;
  }

  @media only screen and (max-width: 1200px) {
    padding: 60px 30px 20px;
  }

  @media only screen and (max-width: 942px) {
    padding: 60px 15px 20px;
  }

  @media only screen and (max-width: 768px) {
    padding: 20px 15px 20px;
  }
`;

const ClientsWrapper = styled.div`
  width: 100%;
  max-width: 1076px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
`;

const ClientsImage = styled.img`
  @media only screen and (max-width: 768px) {
    width: 156px;
  }

  @media only screen and (max-width: 361px) {
    width: 130px;
  }
`;

const HeroSection = () => {
  const [url, setUrl] = useState("");

  useEffect(() => {
    const getCookieValue = (name) => {
      const cookieName = `${name}=`;
      const decodedCookie = decodeURIComponent(document.cookie);
      const cookieArray = decodedCookie.split(";");
      for (let i = 0; i < cookieArray.length; i++) {
        let cookie = cookieArray[i];
        while (cookie.charAt(0) === " ") {
          cookie = cookie.substring(1);
        }
        if (cookie.indexOf(cookieName) === 0) {
          return cookie.substring(cookieName.length, cookie.length);
        }
      }
      return "";
    };

    const fbcValue = getCookieValue("_fbc");
    console.log(fbcValue);

    const fetchIpAddress = async () => {
      try {
        const response = await fetch("https://api.ipify.org/?format=json");
        const data = await response.json();
        return data.ip;
      } catch (error) {
        console.error("Error fetching IP address:", error);
        return ""; // Return empty string in case of error
      }
    };

    const fetchData = async () => {
      const ipAddress = await fetchIpAddress();
      const userAgent = navigator.userAgent;

      const capiUrl = `https://bles-software.typeform.com/to/cexirQkC?utm_medium=${ipAddress}&utm_source=${userAgent}&utm_campaign=${fbcValue}`;

      setUrl(capiUrl);
    };

    fetchData();
  }, []);
  return (
    <HeroContainer>
      <HeroInnerContainer>
        <HeroContentWrapper>
          <LeftContentWrapper>
            <TextTitle>
              We build hands-off growth systems for B2B{" "}
              <CustomUnderline>founders</CustomUnderline>
            </TextTitle>
            <DescText>
              Find the perfect offer, automate your lead acquisition, & solve
              your project management woes—all while scaling your B2B business
              to 8 figures.
            </DescText>
            <ButtonGroupContainer>
              <IntroButtonContainer>
                <IntroButton
                  type="primary"
                  htmlType="submit"
                  size="large"
                  onClick={() => {
                    window.open(url, "_blank");
                  }}
                >
                  Book intro call →
                </IntroButton>
              </IntroButtonContainer>
              <ViewPricingContainer>
                <Link
                  to={"pricing"}
                  spy={true}
                  smooth={true}
                  offset={0}
                  duration={1000}
                >
                  <IntroButton type="primary" htmlType="submit" size="large">
                    <ViewPricingText>View Pricing</ViewPricingText>
                  </IntroButton>
                </Link>
              </ViewPricingContainer>
            </ButtonGroupContainer>
          </LeftContentWrapper>
          <RightContentWrapper>
            <RightImage src={"/assets/right-hero-img.svg"} alt="Landing page" />
            <Ellipse1 src={"/assets/ellipse-1.svg"} alt="ellipse1" />
            <Ellipse2 src={"/assets/ellipse-2.svg"} alt="ellipse2" />
            <Ellipse3 src={"/assets/ellipse-3.svg"} alt="ellipse3" />
            <Ellipse4 src={"/assets/ellipse-4.svg"} alt="ellipse4" />
            <Ellipse5 src={"/assets/ellipse-5.svg"} alt="ellipse5" />
          </RightContentWrapper>
        </HeroContentWrapper>

        <ClientContentWrapper>
          <ClientsWrapper>
            <ClientsImage src={"/assets/client-placeholder.svg"} alt="client" />{" "}
            <ClientsImage src={"/assets/client-placeholder.svg"} alt="client" />{" "}
            <ClientsImage src={"/assets/client-placeholder.svg"} alt="client" />{" "}
            <ClientsImage src={"/assets/client-placeholder.svg"} alt="client" />
          </ClientsWrapper>
        </ClientContentWrapper>
      </HeroInnerContainer>
    </HeroContainer>
  );
};

export default HeroSection;
