import { Steps } from "antd";
import React from "react";
import styled from "styled-components";

const ApproachSectionContainer = styled.div`
  width: 100%;
  background-color: #000e14;
  padding: 60px 0 80px;
`;

const ApproachSectionInnerContainer = styled.div`
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;

  @media only screen and (max-width: 1430px) {
    max-width: 1250px;
  }
`;

const ApproachSectionContentWrapper = styled.div`
  padding: 0;

  @media only screen and (max-width: 1280px) {
    padding: 0 10px;
  }

  @media only screen and (max-width: 1200px) {
    padding: 0 30px;
  }

  @media only screen and (max-width: 942px) {
    padding: 0 15px;
  }
`;

const ApproachInnerContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 40px;

  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }
`;

const LeftWrapper = styled.div`
  width: 45%;
  flex: 0 0 45%;

  @media only screen and (max-width: 767px) {
    width: 100%;
    flex: 0 0 100%;
  }
`;

const PageTitle = styled.h2`
  font-size: 18px;
  font-weight: 500;
  line-height: 21.94px;
  letter-spacing: 0.9599999785423279px;
  text-align: left;
  color: #0fd4fb;

  @media only screen and (max-width: 600px) {
    font-size: 16px;
    line-height: 19.5px;
  }
`;

const LeftSectionTitle = styled.h2`
  color: #fff;
  font-size: 36px;
  font-weight: 600;
  line-height: 44.14px;
  letter-spacing: 0.9599999785423279px;
  text-align: left;
  padding: 20px 0;

  @media only screen and (max-width: 600px) {
    font-size: 20px;
    line-height: 24.52px;
  }
`;

const LeftSectionSmallText = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #fff;

  @media only screen and (max-width: 600px) {
    font-size: 14px;
    line-height: 23.8px;
  }
`;

const MythSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 20px;
`;

const MythCmpWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  justify-content: flex-start;
  width: 100%;
`;

const MythIcon = styled.img`
  width: 30px;
  height: 30px;
`;

const MythContentWrapper = styled.div``;

const MythContentTitle = styled.h2`
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;

  @media only screen and (max-width: 600px) {
    font-size: 16px;
    line-height: 28px;
  }
`;

const MythContentDesc = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #fff;
  padding-top: 10px;

  @media only screen and (max-width: 600px) {
    font-size: 14px;
    line-height: 23.8px;
  }
`;

const RightWrapper = styled.div`
  background-color: #02212a;
  border-radius: 16px;
  padding: 30px 20px;
  flex: 0 0 45%;
  width: 45%;

  @media only screen and (max-width: 767px) {
    width: fit-content;
    flex: 0 0 100%;
    padding: 30px 10px;
  }
`;

const StepWrapper = styled.div`
  & .ant-steps-item-title {
    font-size: 24px;
    font-weight: 500;
    line-height: 29.26px !important;
    text-align: left;
    color: #fff !important;

    @media only screen and (max-width: 600px) {
      font-size: 18px;
      line-height: 21.94px;
    }
  }

  & .ant-steps-item-description {
    color: #fff !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px !important;
    text-align: left;
    padding-top: 15px;
  }

  & .ant-steps-item-content {
    margin-bottom: 15px;
  }

  & .ant-steps-item-icon {
    width: 30px;
    height: 30px !important;
    border-radius: 50%;
    background: linear-gradient(
      90deg,
      #0fd4fb 0%,
      #097e95 50%,
      #0fd4fb 100%
    ) !important;
  }

  & .ant-steps-icon {
    font-size: 20px !important;
    font-weight: 700;
    line-height: 24.38px !important;
    color: #fff !important;
  }

  & .ant-steps-item-tail::after {
    background-color: #fff !important;
  }
`;

const Approach = () => {
  const MythCmp = ({ data }) => {
    return (
      <MythCmpWrapper>
        <MythIcon src={"/assets/myth-icon.svg"} />
        <MythContentWrapper>
          <MythContentTitle>{data.title}</MythContentTitle>
          <MythContentDesc>{data.desc}</MythContentDesc>
        </MythContentWrapper>
      </MythCmpWrapper>
    );
  };
  return (
    <ApproachSectionContainer>
      <ApproachSectionInnerContainer>
        <ApproachSectionContentWrapper>
          <ApproachInnerContentWrapper>
            <LeftWrapper>
              <PageTitle>OUR APPROACH</PageTitle>
              <LeftSectionTitle>
                First principles systems for better ops
              </LeftSectionTitle>
              <LeftSectionSmallText>
                Coaching is a human service—clients pay just as much for the
                knowledge they receive as the connection that comes with it.
                With that in mind, we built a series of human centric systems
                that included onboarding, sales tracking, and coaching
                fulfillment.
              </LeftSectionSmallText>
              <MythSection>
                {[
                  {
                    title: "Myth: sales requires a ton of time",
                    desc: "Lorem ipsum dolor sit amet consectetur. Ipsum amet lorem nisi sapien aliquam massa. Odio velit placerat accumsan diam neque donec tortor arcu tellus. Nisl ullamcorper ut rhoncus integer mi pellentesque. Metus amet vel id morbi nibh.",
                  },
                  {
                    title: "Myth: cold outreach is dead",
                    desc: "Lorem ipsum dolor sit amet consectetur. Ipsum amet lorem nisi sapien aliquam massa. Odio velit placerat accumsan diam neque donec tortor arcu tellus. Nisl ullamcorper ut rhoncus integer mi pellentesque. Metus amet vel id morbi nibh.",
                  },
                ]?.map((v, i) => {
                  return <MythCmp key={i} data={v} />;
                })}
              </MythSection>
            </LeftWrapper>
            <RightWrapper>
              <StepWrapper>
                <Steps
                  direction="vertical"
                  current={3}
                  items={[
                    {
                      title: "Build",
                      description:
                        "Lorem ipsum dolor sit amet consectetur. Lectus consectetur dui eleifend ac mi. Velit aliquam eget accumsan eget suspendisse. At proin tellus nunc nam ullamcorper nullam. Non faucibus eu gravida.",
                      icon: 1,
                    },
                    {
                      title: "Track",
                      description:
                        "Lorem ipsum dolor sit amet consectetur. Lectus consectetur dui eleifend ac mi. Velit aliquam eget accumsan eget suspendisse. At proin tellus nunc nam ullamcorper nullam. Non faucibus eu gravida.",
                      icon: 2,
                    },
                    {
                      title: "Fulfil",
                      description:
                        "Lorem ipsum dolor sit amet consectetur. Lectus consectetur dui eleifend ac mi. Velit aliquam eget accumsan eget suspendisse. At proin tellus nunc nam ullamcorper nullam. Non faucibus eu gravida.",
                      icon: 3,
                    },
                  ]}
                />
              </StepWrapper>
            </RightWrapper>
          </ApproachInnerContentWrapper>
        </ApproachSectionContentWrapper>
      </ApproachSectionInnerContainer>
    </ApproachSectionContainer>
  );
};

export default Approach;
