import React from "react";
import styled from "styled-components";
import CustomDoubleTitle from "../General/CustomDoubleTitle";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectCards, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-cards";
import {
  ArrowIosBackOutline,
  ArrowIosForwardOutline,
} from "@styled-icons/evaicons-outline";

const TestimonialSectionContainer = styled.div`
  width: 100%;
  background-color: #000e14;
  padding: 40px 0;
`;

const TestimonialSectionInnerContainer = styled.div`
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;

  @media only screen and (max-width: 1430px) {
    max-width: 1250px;
  }
`;

const TestimonialSectionContentWrapper = styled.div`
  padding: 0;

  @media only screen and (max-width: 1280px) {
    padding: 0 10px;
  }

  @media only screen and (max-width: 1200px) {
    padding: 0 30px;
  }

  @media only screen and (max-width: 942px) {
    padding: 0 15px;
  }
`;

const TestimonialWrapper = styled.div`
  padding: 50px 0 30px;
  position: relative;

  & > .arrow {
    position: absolute;
    top: 40%;
    margin-top: -5px;
    cursor: pointer;

    @media only screen and (max-width: 650px) {
      display: none;
    }
  }

  & > .arrow.arrow-left {
    left: 80px;

    @media only screen and (max-width: 844px) {
      left: 0px;
    }
  }
  & > .arrow.arrow-right {
    right: 80px;

    @media only screen and (max-width: 844px) {
      right: 0px;
    }
  }
`;

const EachTestimonialParentWrapper = styled.div`
  margin: 0 auto;
  max-width: 550px;
  width: 100%;
  position: relative;
  z-index: 100;
`;

const EachTestimonialWrapper = styled.div`
  width: 100%;
  min-height: 300px;
  border-radius: 16px;
  background: rgb(52, 70, 95);
  background: -moz-linear-gradient(
    42deg,
    rgba(52, 70, 95, 1) 38%,
    rgba(110, 102, 80, 1) 65%,
    rgba(65, 102, 118, 1) 100%
  );
  background: -webkit-linear-gradient(
    42deg,
    rgba(52, 70, 95, 1) 38%,
    rgba(110, 102, 80, 1) 65%,
    rgba(65, 102, 118, 1) 100%
  );
  background: linear-gradient(
    42deg,
    rgba(52, 70, 95, 1) 38%,
    rgba(110, 102, 80, 1) 65%,
    rgba(65, 102, 118, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#34465f",endColorstr="#416676",GradientType=1);
`;

const EachTestimonialInnerWrapper = styled.div`
  padding: 20px 15px;
`;

const EachTestimonialContentWrapper = styled.div`
  display: flex;
  gap: 30px;
  align-items: flex-start;

  @media only screen and (max-width: 600px) {
    gap: 20px;
  }
`;

const ImageWrapper = styled.div`
  width: 88px;
  display: none;

  @media only screen and (max-width: 600px) {
    width: 44.79px;
  }
`;

const Image = styled.img`
  border-radius: 16px;
  width: 88px;

  @media only screen and (max-width: 600px) {
    width: 44.79px;
  }
`;

const RightWrapper = styled.div``;

const TestimonialText = styled.p`
  color: #fff;
  /* font-size: 18px; */
  font-size: 14px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;

  @media only screen and (max-width: 600px) {
    font-size: 12px;
    line-height: 18px;
  }
`;

const FooterWrapper = styled.div`
  display: flex;
  padding-top: 30px;
  padding-right: 20px;
  justify-content: flex-end;

  @media only screen and (max-width: 600px) {
    padding-right: 0;
  }
`;

const FooterTextWrapper = styled.div`
  text-align: left;
`;

const Name = styled.h2`
  font-size: 16px;
  font-weight: 600;
  line-height: 19.5px;
  letter-spacing: 0.9599999785423279px;
  text-align: left;
  color: #fff;

  @media only screen and (max-width: 600px) {
    font-size: 14px;
    line-height: 17.07px;
  }
`;

const Role = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 17.07px;
  letter-spacing: 0.9599999785423279px;
  text-align: left;
  color: #fff;
  padding-top: 8px;

  @media only screen and (max-width: 600px) {
    font-size: 12px;
    line-height: 14.63px;
  }
`;

const ReviewPagination = styled.div`
  padding-top: 20px;
  text-align: center;

  & > .swiper-pagination-bullet {
    width: 14px;
    height: 14px;
    margin: 0 5px;
    background: #d9d9d9;
    opacity: 0.6;
    cursor: pointer;

    @media only screen and (max-width: 600px) {
      width: 9px;
      height: 9px;
    }
  }

  & > .swiper-pagination-bullet-active {
    background: #1e94ab;
  }
`;

const ArrowImgWrapper = styled.div``;

const TestimonialSection = () => {
  const EachTestimonialCmp = ({ data }) => {
    return (
      <EachTestimonialWrapper>
        <EachTestimonialInnerWrapper>
          <EachTestimonialContentWrapper>
            <ImageWrapper>
              <Image src={data.image} alt={data.name} />
            </ImageWrapper>
            <RightWrapper>
              <TestimonialText>{data.testimonial}</TestimonialText>
              <FooterWrapper>
                <FooterTextWrapper>
                  <Name>{data.name}</Name>
                  <Role>{data.role}</Role>
                </FooterTextWrapper>
              </FooterWrapper>
            </RightWrapper>
          </EachTestimonialContentWrapper>
        </EachTestimonialInnerWrapper>
      </EachTestimonialWrapper>
    );
  };
  return (
    <TestimonialSectionContainer>
      <TestimonialSectionInnerContainer>
        <TestimonialSectionContentWrapper>
          <CustomDoubleTitle
            firstTitle={"TESTIMONIAL"}
            secondTitle={"Hear What Some Of Our Clients Have To Say"}
          />

          <TestimonialWrapper>
            <EachTestimonialParentWrapper>
              <Swiper
                className="review-carousel"
                modules={[Autoplay, Pagination, Navigation, EffectCards]}
                loop={true}
                // effect={"cards"}
                grabCursor={true}
                // cardsEffect={{ perSlideOffset: 1, perSlideRotate: 1 }}
                watchSlidesVisibility={true}
                slidesPerView={1}
                autoplay={{
                  delay: 5000,
                }}
                pagination={{
                  clickable: true,
                  el: ".review-pagination",
                }}
                navigation={{ nextEl: ".arrow-right", prevEl: ".arrow-left" }}
                speed={1500}
                style={{ width: "100%" }}
                spaceBetween={10}
              >
                {[
                  {
                    image: "/assets/testimonial.jpg",
                    testimonial:
                      "I am so glad I chose to work with Stas and his team to develop an AI app for my digital course. Stas and his team took exceptional care of our project, and while there were many unforeseen challenges that arose due to the nature of the project, Stas and his team were so committed to getting the project done perfectly. I would highly recommend working with Stas - his communication style was very thorough and he truly wants his clients to be happy. It's rare to find someone who is so meticulous about creating a great product, and that's Stas!",
                    name: "Bleeckerbliss",
                    role: "United States",
                  },
                  {
                    image: "/assets/testimonial.jpg",
                    testimonial:
                      "Stas and his team are true professionals whom I feel comfortable relying on. The project went beyond my expectations, delivering a high quality result.",
                    name: "Djfreddiazo",
                    role: "Cyprus",
                  },
                  {
                    image: "/assets/testimonial.jpg",
                    testimonial:
                      "Working with Stas and his team was a great experience. Very professional and committed to excellence. Easy to communicate with and was very accommodating as we went through the project. I'd definitely recommend him and anticipate using him again for some future projects!",
                    name: "Jeffhickey",
                    role: "United States",
                  },
                  {
                    image: "/assets/testimonial.jpg",
                    testimonial:
                      "I cannot say enough positive things about our experience working with this exceptional developer. Their remarkable proficiency and dedication to our project went above and beyond our expectations. From the outset, they demonstrated a profound understanding of our objectives, translating them into effective and innovative solutions. The reliability with which tasks were executed, paired with their ability to anticipate potential obstacles, resulted in a smooth and successful project implementation. Their in-depth knowledge and technical skills are truly impressive. They have a keen eye for detail, ensuring that no aspect of the project was overlooked. They continuously delivered high",
                    name: "Mmindependent",
                    role: "Italy",
                  },
                  {
                    image: "/assets/testimonial.jpg",
                    testimonial:
                      "I wish to thank Stas and his team for a wonderful job done. Also thanking him for his continous support to deliver the project with highest standards. He also went beyond to help me with all the challenging portion of thr project as well. Really appreciate. Giving you my highest recommendations!",
                    name: "Jspc22",
                    role: "Australia",
                  },
                  {
                    image: "/assets/testimonial.jpg",
                    testimonial:
                      "The team was responsive, courteous, and skilled. Highly recommend.",
                    name: "Tracytroyer",
                    role: "United States",
                  },
                ]?.map((v, i) => {
                  return (
                    <SwiperSlide key={i} style={{ width: "100%" }}>
                      <EachTestimonialCmp data={v} />
                    </SwiperSlide>
                  );
                })}
              </Swiper>

              <ReviewPagination className="review-pagination"></ReviewPagination>
            </EachTestimonialParentWrapper>
            <ArrowImgWrapper className="arrow-left arrow">
              <ArrowIosBackOutline size={50} color="#AEABAB" />
            </ArrowImgWrapper>

            <ArrowImgWrapper className="arrow-right arrow">
              <ArrowIosForwardOutline size={50} color="#AEABAB" />
            </ArrowImgWrapper>
          </TestimonialWrapper>
        </TestimonialSectionContentWrapper>
      </TestimonialSectionInnerContainer>
    </TestimonialSectionContainer>
  );
};

export default TestimonialSection;
