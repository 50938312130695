import React from "react";
import styled from "styled-components";
import CustomDoubleTitle from "../../General/CustomDoubleTitle";
import { Element } from "react-scroll";

const ServicesSectionContainer = styled.div`
  width: 100%;
  background-color: #000e14;
  padding: 40px 0;
`;

const ServicesSectionInnerContainer = styled.div`
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;

  @media only screen and (max-width: 1430px) {
    max-width: 1250px;
  }
`;

const ServicesSectionContentWrapper = styled.div`
  padding: 0;

  @media only screen and (max-width: 1280px) {
    padding: 0 10px;
  }

  @media only screen and (max-width: 1200px) {
    padding: 0 30px;
  }

  @media only screen and (max-width: 942px) {
    padding: 0 15px;
  }
`;

const ServicesContainer = styled.div`
  padding: 80px 0 20px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
  row-gap: 50px;
  flex-wrap: wrap;

  /* @media only screen and (max-width: 700px) {
    flex-direction: column;
    gap: 50px;
  } */
`;

const EachServiceContainer = styled.div`
  position: relative;
  border: 1px solid #0b3e40;
  border-radius: ${(props) => (props.middle ? "16px" : "56px 16px 16px 56px")};
  flex: 0 0 32%;
  width: 32%;
  height: 266px;

  @media only screen and (max-width: 969px) {
    flex: 0 0 48%;
    width: 48%;
  }

  @media only screen and (max-width: 637px) {
    flex: 0 0 100%;
    width: 100%;
    height: 220px;
  }
`;

const EachServiceContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 30px 10px;
`;

const EachServiceTitle = styled.div`
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.94px;
  text-align: center;
  letter-spacing: 0.9599999785423279px;

  @media only screen and (max-width: 600px) {
    font-size: 16px;
    line-height: 19.5px;
  }
`;

const EachServiceDesc = styled.p`
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;

  @media only screen and (max-width: 600px) {
    font-size: 14px;
    line-height: 23.8px;
  }
`;

const IconWrapper = styled.div`
  width: 40px;
  height: 22px;
  border-radius: 32px;
  padding: 6px;
  background-color: #000e14;
  border: 1px solid #0b3e40;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -20px;
`;

const IconImg = styled.img``;

const ServicesSection = () => {
  const EachServiceCmp = ({ data }) => {
    return (
      <EachServiceContainer middle={data?.middle}>
        <EachServiceContentWrapper>
          <IconWrapper>
            <IconImg src={data.icon} alt={data.title} />
          </IconWrapper>
          <EachServiceTitle>{data.title}</EachServiceTitle>
          <EachServiceDesc>{data.desc}</EachServiceDesc>
        </EachServiceContentWrapper>
      </EachServiceContainer>
    );
  };
  return (
    <Element name="services">
      {" "}
      <ServicesSectionContainer>
        <ServicesSectionInnerContainer>
          <ServicesSectionContentWrapper>
            <CustomDoubleTitle
              firstTitle={"SERVICES"}
              secondTitle={"What our clients get"}
            />

            <ServicesContainer>
              {[
                {
                  title: "Lead generation",
                  desc: "Scalable, affordable outbound & marketing systems to grow your company on autopilot.",
                  icon: "/assets/settings.svg",
                },
                {
                  middle: true,
                  title: "Project management",
                  desc: "Bespoke project management systems used by 8-figure agencies & SaaS companies.",
                  icon: "/assets/clipboard.svg",
                },
                {
                  title: "Hiring systems",
                  desc: "Automated processes that source, contact, and evaluate candidates for you.",
                  icon: "/assets/success.svg",
                },
                {
                  title: "AI service fulfillment",
                  desc: "AI that automates key steps in your fulfillment process to reduce payroll.",
                  icon: "/assets/rocket.svg",
                },
                {
                  middle: true,
                  title: "CRM buildouts",
                  desc: "Sales systems to track, iterate, and scale growth just like an 8-figure company.",
                  icon: "/assets/user.svg",
                },
                {
                  title: "SOPs & consultation",
                  desc: "We'll help clarify your offer, show you what 8-figure companies are doing, and build you a library of SOPs.",
                  icon: "/assets/ai.svg",
                },
              ].map((v, i) => {
                return <EachServiceCmp key={i} data={v} />;
              })}
            </ServicesContainer>
          </ServicesSectionContentWrapper>
        </ServicesSectionInnerContainer>
      </ServicesSectionContainer>
    </Element>
  );
};

export default ServicesSection;
