import React from "react";
import styled from "styled-components";

const FooterSectionContainer = styled.div`
  width: 100%;
  background-color: #000e14;
  padding: 40px 0 20px;
  position: relative;
`;

const FooterSectionInnerContainer = styled.div`
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;

  @media only screen and (max-width: 1430px) {
    max-width: 1250px;
  }
`;

const FooterSectionContentWrapper = styled.div`
  padding: 0;

  @media only screen and (max-width: 1280px) {
    padding: 0 10px;
  }

  @media only screen and (max-width: 1200px) {
    padding: 0 30px;
  }

  @media only screen and (max-width: 942px) {
    padding: 0 15px;
  }
`;

// const FirstRow = styled.div`
//   display: flex;
//   justify-content: space-between;
//   gap: 10px;
//   align-items: center;
//   padding-bottom: 40px;

//   @media only screen and (max-width: 622px) {
//     flex-direction: column;
//     align-items: flex-start;
//     gap: 50px;
//   }
// `;

// const Logo = styled.img`
//   cursor: pointer;
//   width: 40px;
// `;

// const SocialMediaWrapper = styled.div`
//   display: flex;
//   align-items: center;
//   gap: 20px;
//   width: 18%;
// `;

// const SocialMediaIcon = styled.img`
//   cursor: pointer;
//   width: 27px;
//   aspect-ratio: 1;
// `;

// const SecondRow = styled.div`
//   display: flex;
//   align-items: flex-start;
//   justify-content: space-between;
//   gap: 10px;

//   @media only screen and (max-width: 622px) {
//     flex-direction: column;
//     align-items: flex-start;
//     gap: 50px;
//   }
// `;

// const EachFooterColumn = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: flex-start;
//   gap: 10px;
//   flex: ${(props) => (props.width ? `0 0 ${props.width}` : "0 0 100%")};
//   width: ${(props) => (props.width ? props.width : "100%")};

//   @media only screen and (max-width: 622px) {
//     flex: 0 0 100%;
//     width: 100%;
//   }
// `;

// const ColumnTitle = styled.h2`
//   font-size: 18px;
//   font-weight: 500;
//   line-height: 24px;
//   text-align: left;
//   color: #fff;

//   @media only screen and (max-width: 600px) {
//     font-size: 16px;
//     font-weight: 500;
//     line-height: 24px;
//   }
// `;

// const MenuWrapper = styled.div`
//   padding-top: 15px;
//   display: flex;
//   flex-direction: column;
//   gap: 15px;
// `;

// const MenuItem = styled.p`
//   color: #fff;
//   font-size: 16px;
//   font-weight: 400;
//   line-height: 19.2px;
//   text-align: left;

//   @media only screen and (max-width: 600px) {
//     font-size: 14px;
//     font-weight: 400;
//     line-height: 16.8px;
//   }
// `;

const CopyrightWrapper = styled.div`
  padding: 0 10px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #fff;
  margin: 0;

  @media only screen and (max-width: 600px) {
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
  }
`;

const Footer = () => {
  // const navigate = useNavigate();
  return (
    <FooterSectionContainer>
      <FooterSectionInnerContainer>
        <FooterSectionContentWrapper>
          {/* <FirstRow>
            <div
              onClick={() => {
                navigate("/");
              }}
              style={{
                display: "flex",
                alignItems: "flex-end",
                gap: "10px",
                cursor: "pointer",
              }}
            >
              <Logo src={"/assets/logo.png"} alt="Logo" />{" "}
              <span style={{ color: "#fff", fontSize: "16px" }}>
                Bles Software
              </span>
            </div>
            <SocialMediaWrapper>
              <SocialMediaIcon src={"/assets/facebook.svg"} alt="Facebook" />
              <SocialMediaIcon src={"/assets/twitter.svg"} alt="Twitter" />
              <SocialMediaIcon src={"/assets/instagram.svg"} alt="Instagram" />
            </SocialMediaWrapper>
          </FirstRow> */}
          {/* <SecondRow>
            {[
              {
                title: "General",
                items: [
                  { name: "Services" },
                  { name: "Blog" },
                  { name: "Pricing" },
                  { name: "Contact us" },
                ],
              },
              {
                title: "Case Studies",
                items: [
                  { name: "Content Marketing" },
                  { name: "Print-on-Demand" },
                  { name: "Conversion Rate Optimization" },
                ],
              },
              {
                title: "Legal",
                items: [
                  { name: "Privacy Policy" },
                  { name: "Terms & Conditions" },
                  { name: "Cookie Policy" },
                ],
              },
            ]?.map((v, i) => {
              return (
                <EachFooterColumn key={i} width={i === 1 ? "30%" : "18%"}>
                  <ColumnTitle>{v.title}</ColumnTitle>

                  <MenuWrapper>
                    {v.items?.map((eachItem, eachItemIndex) => {
                      return (
                        <MenuItem key={eachItemIndex}>
                          {eachItem?.name}
                        </MenuItem>
                      );
                    })}
                  </MenuWrapper>
                </EachFooterColumn>
              );
            })}
          </SecondRow>
          <Divider style={{ backgroundColor: "#0B3E40", margin: "20px 0" }} /> */}
          <CopyrightWrapper>
            © Copyright 2024. All rights reserved{" "}
          </CopyrightWrapper>
        </FooterSectionContentWrapper>
      </FooterSectionInnerContainer>
    </FooterSectionContainer>
  );
};

export default Footer;
