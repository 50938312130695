import React from "react";
import Header from "../../components/General/Header";
import Footer from "../../components/General/Footer";
import BlogSection from "../../components/Blog/BlogSection";
import Cta from "../../components/General/Cta";

const Blog = () => {
  return (
    <>
      <Header />
      <BlogSection />
      <Cta
        bigTitle={"Get started today with a 30min intro call"}
        smallTitle={
          "We'll analyze your current processes and provide you with a free analysis on how to optimize your agency - no strings attached. Get tens of thousands of dollars of value, absolutely free."
        }
        buttonText={"Scale my Company →"}
      />
      <Footer />
    </>
  );
};

export default Blog;
