import React from "react";
import HeroSection from "../../components/Home/HeroSection";
import OperationSection from "../../components/Home/OperationSection";
import ServicesSection from "../../components/Home/ServicesSection/ServicesSection";
import FaqSection from "../../components/Home/FaqSection";
import Cta from "../../components/General/Cta";
import Footer from "../../components/General/Footer";
import Header from "../../components/General/Header";
import CompareSection from "../../components/Home/CompareSection";
import CaseStudySection from "../../components/Home/CaseStudySection";
import TestimonialSection from "../../components/Home/TestimonialSection";
import SubscriptionStep from "../../components/Home/SubscriptionStep";
import PricingSection from "../../components/Home/PricingSection";

const Home = () => {
  return (
    <>
      <Header />
      <HeroSection />
      <OperationSection />
      <ServicesSection />
      <TestimonialSection />
      <CompareSection />
      <CaseStudySection />
      <SubscriptionStep />
      <PricingSection />
      <FaqSection />
      <Cta
        bigTitle={"Get started today with a 30min intro call"}
        smallTitle={
          "A no-strings-attached meet and greet + consultation with Stas 👋 Book your call now."
        }
        buttonText={"Book intro call →"}
      />
      <Footer />
    </>
  );
};

export default Home;
