import React from "react";
import styled from "styled-components";
import CustomUnderline from "../General/CustomUnderline";

const BlogSectionContainer = styled.div`
  width: 100%;
  background-color: #000e14;
  padding: 40px 0 80px;
`;

const BlogSectionInnerContainer = styled.div`
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;

  @media only screen and (max-width: 1430px) {
    max-width: 1250px;
  }
`;

const BlogSectionContentWrapper = styled.div`
  padding: 0;

  @media only screen and (max-width: 1280px) {
    padding: 0 10px;
  }

  @media only screen and (max-width: 1200px) {
    padding: 0 30px;
  }

  @media only screen and (max-width: 942px) {
    padding: 0 15px;
  }
`;

const BlogInnerContentTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  max-width: 698px;
  width: 100%;
  margin: 0 auto;
`;

const PageTitle = styled.h2`
  font-size: 18px;
  font-weight: 500;
  line-height: 21.94px;
  letter-spacing: 0.9599999785423279px;
  text-align: center;
  color: #0fd4fb;

  @media only screen and (max-width: 600px) {
    font-size: 16px;
    line-height: 19.5px;
  }
`;

const BigTitle = styled.h2`
  color: #fff;
  font-size: 48px;
  font-weight: 600;
  line-height: 72px;
  letter-spacing: 1.2000000476837158px;
  text-align: center;

  @media only screen and (max-width: 600px) {
    font-size: 24px;
    line-height: 36px;
  }
`;

const PageDesc = styled.p`
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  padding-top: 20px;
  text-align: center;

  @media only screen and (max-width: 600px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

const BlogAreaContainer = styled.div`
  max-width: 1279px;
  width: 100%;
  margin: 0 auto;
  padding-top: 90px;
`;

const BlogWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  row-gap: 30px;

  @media only screen and (max-width: 896px) {
    row-gap: unset;
    gap: 30px;
  }
`;

const BlogCmpWrapper = styled.div`
  height: 309px;
  border-radius: 16px;
  /* background: url("/assets/pattern.svg"); */
  background: ${(props) => props.image && `url(${props.image})`};
  background-repeat: no-repeat;
  width: 32%;
  flex: 0 0 32%;
  background-size: cover;
  cursor: pointer;
  position: relative;

  @media only screen and (max-width: 896px) {
    width: 48%;
    flex: 0 0 48%;
  }

  @media only screen and (max-width: 779px) {
    width: 46%;
    flex: 0 0 46%;
  }

  @media only screen and (max-width: 640px) {
    width: 100%;
    flex: 0 0 100%;
  }
`;

const BlogInfoSection = styled.div`
  border-radius: 0 0 16px 16px;
  background: #000e1466;
  position: absolute;
  bottom: 0;
  height: 151px;
  padding: 15px 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media only screen and (max-width: 640px) {
    width: fit-content;
    height: 120px;
  }
`;

const FirstInfoRow = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  justify-content: flex-start;
`;

const TagWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 5px;
  align-items: center;
`;

const Bullet = styled.div`
  background-color: #acacac;
  border: 2px solid #acacac33;
  width: 6px;
  height: 6px;
  border-radius: 50%;
`;

const TagText = styled.p`
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;

  @media only screen and (max-width: 600px) {
    font-size: 12px;
    line-height: 18px;
  }
`;

const BlogTitle = styled.h2`
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  text-align: left;
  /* padding: 15px 0; */

  @media only screen and (max-width: 600px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

const OtherInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const OtherInfo = styled.p`
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 18px;
  color: #fff;
`;

const BlogSection = () => {
  const BlogCmp = ({ data }) => {
    return (
      <BlogCmpWrapper image={data.image}>
        <BlogInfoSection>
          <FirstInfoRow>
            {data.tags?.map((v, i) => {
              return (
                <TagWrapper key={i}>
                  <Bullet />
                  <TagText>{v}</TagText>
                </TagWrapper>
              );
            })}
          </FirstInfoRow>
          <BlogTitle>{data.title}</BlogTitle>
          <OtherInfoWrapper>
            <OtherInfo>{data.date}</OtherInfo>
            <Bullet style={{ width: "2px", height: "2px" }} />
            <OtherInfo>{data.duration} read</OtherInfo>
          </OtherInfoWrapper>
        </BlogInfoSection>
      </BlogCmpWrapper>
    );
  };
  return (
    <BlogSectionContainer>
      <BlogSectionInnerContainer>
        <BlogSectionContentWrapper>
          <BlogInnerContentTitleWrapper>
            <PageTitle>BLOG</PageTitle>
            <BigTitle>
              Insights from our <CustomUnderline>team</CustomUnderline>
            </BigTitle>
            <PageDesc>
              Learn how we use cutting-edge AI to scale companies just like
              yours.
            </PageDesc>
          </BlogInnerContentTitleWrapper>

          <BlogAreaContainer>
            <BlogWrapper>
              {[
                {
                  tags: ["Artificial Intelligence"],
                  title:
                    "Demystifying AI: Navigating the New Frontier of Intelligence.",
                  date: "Feb 8th",
                  duration: "10 mins",
                  image: "/assets/blog-image-1.svg",
                },
                {
                  tags: ["Artificial Intelligence"],
                  title:
                    "Demystifying AI: Navigating the New Frontier of Intelligence.",
                  date: "Feb 8th",
                  duration: "10 mins",
                  image: "/assets/blog-image-2.svg",
                },
                {
                  tags: ["Artificial Intelligence"],
                  title:
                    "Demystifying AI: Navigating the New Frontier of Intelligence.",
                  date: "Feb 8th",
                  duration: "10 mins",
                  image: "/assets/blog-image-3.svg",
                },
                {
                  tags: ["Artificial Intelligence"],
                  title:
                    "Demystifying AI: Navigating the New Frontier of Intelligence.",
                  date: "Feb 8th",
                  duration: "10 mins",
                  image: "/assets/blog-image-1.svg",
                },
                {
                  tags: ["Artificial Intelligence"],
                  title:
                    "Demystifying AI: Navigating the New Frontier of Intelligence.",
                  date: "Feb 8th",
                  duration: "10 mins",
                  image: "/assets/blog-image-2.svg",
                },
                {
                  tags: ["Artificial Intelligence"],
                  title:
                    "Demystifying AI: Navigating the New Frontier of Intelligence.",
                  date: "Feb 8th",
                  duration: "10 mins",
                  image: "/assets/blog-image-3.svg",
                },
              ]?.map((v, i) => {
                return <BlogCmp key={i} data={v} />;
              })}
            </BlogWrapper>
          </BlogAreaContainer>
        </BlogSectionContentWrapper>
      </BlogSectionInnerContainer>
    </BlogSectionContainer>
  );
};

export default BlogSection;
