import React from "react";
import Header from "../../components/General/Header";
import Footer from "../../components/General/Footer";
import ContactSection from "../../components/Contact/ContactSection";

const Contact = () => {
  return (
    <>
      <Header />
      <ContactSection />
      <Footer />
    </>
  );
};

export default Contact;
